@use 'sass:map';
@use '~@angular/material' as mat;

/***************************************************************
 *                       tailwind import                       *
 ***************************************************************/

@import "tailwindcss/base";
@import "tailwindcss/utilities";

/***************************************************************
 *                   create debugging classes                  *
 ***************************************************************/
 

.debug {
  border: 1px dashed red;
}
.debug-2 {
  border: 1px dashed green;
}
.debug-3 {
  border: 1px dashed blue;
}
.bg-yellow {
  background-color: rgb(250, 221, 113) !important;
}

.mat-card{
  border-radius: 9px;
  box-shadow: none;
}

.active{
  background-color: #fefefe;
  font-weight: 700;
  padding: 8px 8px 0 0;
  color: #3f4658;
  border-bottom: 1px solid #b1dffdbe;
  border-width: auto;
}
/***************************************************************
 *                       extracts themes                       *
 ***************************************************************/

@include mat.core();

@mixin extractTheme($theme) {
  $themeConfig: mat.get-color-config($theme);
  $palettes: "primary", "accent", "warn";
  $palette-hues: "default", "lighter", "darker";

  // exposes the color and hues of current theme.
  // for example, if you want a <div> to have this theme's primary color,
  // simply make it <div class='bg-primary-default'></div>
  // by default it will apply contrasting color to the text inside.
  @each $palette in $palettes {
    @each $hue in $palette-hues {
      .bg-#{$palette}-#{$hue} {
        background-color: map.get(map.get($themeConfig, $palette), $hue) !important;
        color: map.get(map.get($themeConfig, $palette), "#{$hue}-contrast");
      }

      .hover\:bg-#{$palette}-#{$hue} {
        &:hover {
          background-color: map.get(map.get($themeConfig, $palette), $hue) !important;
          color: map.get(map.get($themeConfig, $palette), "#{$hue}-contrast");
        }
      }

      .text-#{$palette}-#{$hue} {
        color: map.get(map.get($themeConfig, $palette), $hue) !important;
      }
    }
  }

  // individual component theming
  @include mat.all-component-themes($theme);
}

/***************************************************************
*                           theming                            *
***************************************************************/

$primary-palette: mat.define-palette(mat.$blue-palette);
$accent-palette: mat.define-palette(mat.$teal-palette);

// create light theme
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
    ),
  )
);

/***************************************************************
*                       handles theming                        *
***************************************************************/

// apply light theme by default
@include extractTheme($light-theme);
.light {
  color: #3f4658;
  background-color: #edf0f8;

  .mat-drawer-container {
    background-color: #edf0f8 !important;
  }
}

// apply dark when `dark` is addedn into class.
.dark {
  color: #FFF;
  fill: #ccc;
  background-color: #8E9FBC 0% 0% no-repeat padding-box;;
  // background-color: #3f4658;

  .mat-drawer-container {
    background-color: #8E9FBC 0% 0% no-repeat padding-box;;
  }

  // .basic-table {

  // }
  .my-table {
    .data {
      &:nth-child(even) {
        background: rgb(90, 90, 90);
      }
      &:hover {
        background-color: rgb(93, 93, 97);
        color: white;
      }
    }
  }
  @include extractTheme($dark-theme);
}

/***************************************************************
 *                    Angular Material hacks                   *
 ***************************************************************/

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #edf0f8;
}

// remove margin and padding from mat-form-field!
.no-margin-crap .mat-form-field-wrapper {
  margin: 0 !important;
  padding: 0 !important;

  .mat-form-field-infix {
    padding: 0.5rem 0 !important;
    border-top-width: 0px;

    input {
      margin-top: 3px;
    }
  }
}

.no-margin-bottom .mat-form-field-wrapper {
  margin-bottom: -1.25em !important;
}

/***************************************************************
*                         custom styles                        *
***************************************************************/

// thumbnail div
.thumbnail {
  border-radius: 100%;

  img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    cursor: pointer;
  }
}

.my-table {
  thead {
    border-radius: 10px !important;
  }
  width: 100%;
  th,
  td {
    text-align: center;
    padding: 1vh;
    border: 1px solid #D8DBE6;
  }
  thead {
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
    position: sticky;
    top: 0;
    color: white;
    background-color: #8E9FBC;
    tr {
      border-width: 1px;
      &:nth-last-child(1) {
        border-bottom: 1;
        // box-shadow: 0px 5px 5px rgb(180, 180, 180);
      }
    }
  }
  .parent {
    background-color: rgba(33, 149, 243, 0.2);
    &:nth-child(even){
      background-color: rgba(206, 102, 137, 0.1);
      color: #CE6689 !important;
    }
  }
  .text-head {
    color: #2196F3 !important;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
    text-transform: uppercase;
    padding: 20px 10px;
    margin-left: 6px;
    opacity: 1 !important;
     &:nth-child(even){
      color: #CE6689 !important;
    }
  }
  .data {
    cursor: pointer;
    &:nth-child(even) {
      background: rgba(250, 252, 255, 1);
      color: rgba(67, 67, 67, 1);
    }
    &:hover {
      background-color: rgb(236, 238, 241);
      color: rgba(67, 67, 67, 1);
    }
  }
  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: orange;
  }

  &::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
  }
}

// &:hover {
//   background-color: gray;
//   img {
//     width: 30vw;
//     height: auto;
//     position: fixed;
//     bottom: 10px;
//     left: 10px;
//     border-radius: 2%;
//   }
// }
.backdropBackground {
  filter: blur(10px);
  background-color: #333131;
  opacity: 0.7 !important;
}

//style untuk sidebar layout
.bg-active {
  text-align: left;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #212121 !important;
  opacity: 1;
  background: #2195f345;
  padding: auto;
  border-radius: 9px;
  // opacity: 20%;

  & .mat-icon{
      svg#Layer_2 {
          g#Layer_1-2 {
              g {
                  path.cls-1 {
                      fill: #2196F3;
                  }
              }
              path.cls-1 {
                fill: #2196F3;
            }
          }

      }
  }
}

.mat-card{
  border-radius: 9px !important;
  box-shadow: none !important;
}

::ng-deep .mat-form-field-appearance-outline  {
  .mat-form-field-outline {
     background-color: #fff !important;
     border-radius: 3px;
 }
}